import { checkGAVersion, GA4SocialEvent, GA4AllowEvents } from "~/tools/analytics/gtmTagsGA4";

export default {
  email: (permalink, subject, description, name, companyName) => {
    var emailBody = description;
    var emailSubject = subject;
    if ((emailSubject && emailSubject.includes("{Inviter}")) && name) {
      emailSubject = emailSubject.replaceAll("{Inviter}", name);
    }
    if ((emailSubject && emailSubject.includes("{company}")) && companyName) {
      emailSubject = emailSubject.replaceAll("{company}", companyName);
    }
    if ((emailBody && emailBody.includes("{Inviter}")) && name) {
      emailBody = emailBody.replaceAll("{Inviter}", name);
    }
    if ((emailBody && emailBody.includes("{company}")) && companyName) {
      emailBody = emailBody.replaceAll("{company}", companyName);
    }
    const url = permalink || window.location.href
    if(GA4AllowEvents('Share')){
      if(checkGAVersion('GA4')){
        GA4SocialEvent('Email', 'url', url)
      }
    }
    if(checkGAVersion('GA')){
      const ga = window.ga;
      ga('send', 'social', 'Email', 'Email', url);
    }
    emailSubject = emailSubject && encodeURI(emailSubject);
    emailBody = emailBody && encodeURI(emailBody);
    if(emailBody && emailBody.includes('#LB#')){
      emailBody = emailBody.replaceAll('#LB#','%0D%0A');
    }
    emailBody = emailBody + "%0D%0A" + "%0D%0A" + encodeURIComponent(url) + "%0D%0A" + "%0D%0A";
    window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`
  },

  facebook: (permalink) => {
    const url = permalink || window.location.href;
    if(GA4AllowEvents('Share')){
      if(checkGAVersion('GA4')){
        GA4SocialEvent('Facebook', 'url', url)
      }
    }
    if(checkGAVersion('GA')){
      const ga = window.ga;
      ga('send', 'social', 'Facebook', 'Share', url);
    }
    
    if (window.FB) {
      window.FB.ui({
        method: 'share',
        href: url,
      })
    } else {
      window.open(
        `https://facebook.com/sharer.php?u=${encodeURIComponent(url)}`,
        'share',
        'status=no,toolbar=no,location=no,directories=no,menubar=no'
      )
    }
  },

  twitter: (permalink, title)=> {
    const url = permalink || window.location.hrefs
    if(GA4AllowEvents('Share')){
      if(checkGAVersion('GA4')){
        GA4SocialEvent('Twitter', 'url', url)
      }
    }
    if(checkGAVersion('GA')){
      const ga = window.ga;
      ga('send', 'social', 'Twitter', 'Tweet', url);
    }
    var text = title
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
      'share',
      'status=no,toolbar=no,location=no,directories=no,menubar=no'
    )
  },

  whatsapp: (permalink, title) => {
    const url = permalink || window.location.href
    if(GA4AllowEvents('Share')){
      if(checkGAVersion('GA4')){
        GA4SocialEvent('Whatsapp', 'url', url)
      }
    }
    if(checkGAVersion('GA')){
      const ga = window.ga;
      ga('send', 'social', 'Whatsapp', 'Whatsapp', url);
    }
    var text = title
    const encodedUrl = `whatsapp://send?text=${encodeURIComponent(text)}%20${encodeURIComponent(url)}`
    window.location = encodedUrl
  },

  googlePlus: (permalink) => {
    const url = permalink || window.location.href;
    if(GA4AllowEvents('Share')){
      if(checkGAVersion('GA4')){
        GA4SocialEvent('GooglePlus', 'url', url)
      }
    }
    if(checkGAVersion('GA')){
      const ga = window.ga;
      ga('send', 'social', 'GooglePlus', 'GooglePlus', url);
    }
    window.open(
      `https://plus.google.com/share?url=${encodeURIComponent(url)}`,
      'share',
      'status=no,toolbar=no,location=no,directories=no,menubar=no'
    )
  }
}
