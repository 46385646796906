// Ref: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag

window.dataLayer = window.dataLayer || [];

const GoogleAnalytics4ID = window.settings.analytics && window.settings.analytics.googleAnalyticsIdGA4;
const GoogleAnalyticsID = window.settings.analytics && window.settings.analytics.googleAnalyticsId;
const GoogleAdwordsID = window.settings.analytics && window.settings.analytics.googleAdwordsId;
const GoogleAdwordsConversionLabel = window.settings.analytics && window.settings.analytics.googleAdwordsConversionLabel;
const GoogleAdsRemarketingID = window.settings.analytics && window.settings.analytics.googleAdsRemarketingId;
const GoogleAnalyticsAppWebID = window.settings.analytics && window.settings.analytics.googleAnalyticsAppWebId;

export const checkGAVersion = (googleAnalyticsVersion = '') => {
    if(GoogleAnalytics4ID && googleAnalyticsVersion === 'GA4'){
        return true
    } else if(GoogleAnalyticsID && googleAnalyticsVersion === 'GA'){
        return true
    } else {
        return false
    }
}
// Google Analytics4 Script 
const GA4Script = () => {
    return new Promise (function(resolve, reject){

        if(GoogleAnalytics4ID) {
            var script = document.createElement('script');
            script.type= 'text/javascript';
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id='+ GoogleAnalytics4ID;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        }
    })
}

function gtag(){window.dataLayer.push(arguments);}

export const initGA4 = () => {
    GA4Script()
    .then(()=> {
        gtag('js', new Date());
        if(GoogleAnalytics4ID){
            // gtag('config', GoogleAnalytics4ID, { 'debug_mode':true }); // For Google Adwords with Degug mode
            gtag('config', GoogleAnalytics4ID); // For Google Adwords
        }
        if(GoogleAdwordsID){
            gtag('config', GoogleAdwordsID); // For Google Adwords
        }
        if(GoogleAdsRemarketingID){
            gtag('config', GoogleAdsRemarketingID); // For Google Ads Remarketing
        }
        if(GoogleAnalyticsAppWebID){
            gtag('config', GoogleAnalyticsAppWebID); // For Google Analytics App + Web stream
        }
    })
}

export function pageViewEvent(eventName = 'page_view', eventData) {
    gtag('event', eventName, eventData);
}

export const GA4EcommerceEvent = (props, eventName = "add_to_cart", paymentMode) => {
    const { plan, user } = props
    const pd = (plan && plan.planDetails && plan.planDetails[0]) || {}
    const planDetails = (pd && pd.description) || (plan && plan.description)
    const planAmount = pd && (pd.introductoryPrice || pd.recurringPaymentAmount)
    const countryCode = pd && pd.countryCode
    const currencyCode = pd && pd.recurringPaymentCurrencyCode
    const planDuration = pd && pd.title

    const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const dt = new Date();
    const getMonth = MONTHS[dt.getMonth()];
    const currentDate =  dt.getDate() + "/" + getMonth + "/" + dt.getFullYear();

    const eventItems = {
        'Name': user.name,
        'Email': user.email,
        'userId': user.userId,
        'subscriptionPlan': planDetails,
        'subscriptionStartDate': currentDate,
        'subscriptionEndDate': planDuration,
        'transactionAmount': planAmount,
        'paymentHandler': paymentMode,
        'subscribed_via_platform': 'Web',
        'subscribed_from_country': countryCode,
        'localCurrency': currencyCode,
    }
    // If we use GTM then the items(Email, userId...) of the items array will be displayed on the Analytics dashboard.
    // gtag("event", eventName, {
    //     currency: currencyCode,
    //     value: planAmount,
    //     payment_type: paymentMode,
    //     items: [
    //         eventItems
    //     ]
    // });

    trackEvent(paymentMode, eventName, eventItems)
}

/***************** Start Google Analytics Events ************/
export function GA4TrackECommerceEvent(eventName = 'add_to_cart',  categoryValue = 'category_value', currencyCode = 'USD', planAmount = 0.00, eventItemsObj = {}) {
    // If we use GTM then the items of the items array will be displayed on the Analytics dashboard.
    gtag("event", eventName, {
        currency: currencyCode,
        value: planAmount,
        eventCategory: categoryValue,
        items: [
            eventItemsObj
        ]
    });

    trackEvent(categoryValue, eventName, eventItemsObj)
}

export function trackEvent(categoryValue = 'category_value', actionName = 'action_name', customData = '') {
    gtag('event', actionName, {'eventCategory': categoryValue,
        ...customData
    });
}

export function trackECEvent(actionName = 'action_name', categoryValue = 'category_value', customData = '') {
    gtag('event', actionName, {'eventCategory': categoryValue,
        ...customData
    });
}

export function setCustomDimension(dimensionName = 'setCustomDimensions', customData = '') {
    gtag('set', {[dimensionName]: customData});
}

export function GA4SocialEvent(method = 'Twitter', contentType = 'image', contentData = 'image_url') {
    gtag("event", "share", {
        method: method,
        content_type: contentType,
        item_id: contentData,
    });
}

export const GA4AllowEvents = (eventName) => {
    /*** Currently Allow for all the events **/
    return true

    /*** Srart Currently Disabled this checked. After testing we will upload on prod. **/
    // const allowEvents = (window.settings && window.settings.analyticalEvents && window.settings.analyticalEvents.googleAnalytics) || null
  
    // if(allowEvents && eventName && allowEvents[eventName]){
    //   return allowEvents[eventName]
    // } else{
    //   return false
    // }
    /*** End Currently Disabled this checked. After testing we will upload on prod. **/
}
/**************** End Google Analytics Events ***********/