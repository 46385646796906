import get from 'lodash/get';
import axios from 'axios';
import { getToken } from '../../redux/user/tokens';

export const FB_EVENTS = {
  PAGE_VIEW: 'PageView',
  PURCHASE: 'Purchase',
  ADD_PAYMENT_INFO: 'AddPaymentInfo',
  INITIATE_CHECKOUT: 'InitiateCheckout',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  ADD_TO_CART: 'AddToCart',
  START_TRIAL: 'StartTrial',
  SUBSCRIBE: 'Subscribe',
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_WISHLIST: 'AddToWishlist',
  REMOVED_FROM_WATCHLIST: 'RemovedFromWatchlist',
  SUBSCRIPTION_INITIATED: 'SubscriptionInitiated',
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted',
  SUBSCRIPTION_FAILED: 'SubscriptionFailed',
  TVOD_PURCHASE: 'tvod_purchase',
  TVOD_PURCHASE_COMPLETION: 'tvod_purchase_completion',
  SEARCH: 'Search',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PLAY_STARTED: 'Play Started',
  WATCHED: 'Watched',
};

export const pixelEvents = {
  ADD_TO_CART: 'AddToCart',
  PAGE_VIEW: 'PageView',
  PURCHASE: 'Purchase',
  ADD_PAYMENT_INFO: 'Add Payment Info',
  INITIATE_CHECKOUT: 'Initiate Checkout',
  LOGIN: 'Login',
  VIEW_CONTENT: 'ViewContent',
  COMPLETE_REGISTRATION: 'Complete Registration',
  SUBSCRIPTION_INITIATED: "SubscriptionInitiated",
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted'
}

export const trackFbPixel= ( eventName,payload = {}, eventID ) => {
  if(checkFBAllowEvents(eventName)){
    const socialMedia = window.settings.socialMedia
    const facebook = socialMedia && socialMedia.facebook 
    const pixelId = facebook && facebook.pixelId
    const facebookAdditionalPixelIdsInArray = (facebook && facebook.facebookAdditionalPixelIds && facebook.facebookAdditionalPixelIds.split(',')) || []
    if(pixelId || (facebookAdditionalPixelIdsInArray && facebookAdditionalPixelIdsInArray.length > 0)) {
      window.fbq && window.fbq('track', eventName, payload,{eventID : eventID})
    }
  }
}


export const trackFbEvent = async (
  eventName,
  payload = {},
  eventID
) => {
  if(checkFBAllowEvents(eventName)){
      const site = get(window, 'settings.internalName');
      const { conversionApi: conversionApiEnabled } = get(
        window,
        'settings.analytics.conversionApi'
      ) || {};

      if (!site) return;

    // Will be replaced with actual url
    if (conversionApiEnabled) {
      const url = `${window.settings.trackingBaseUrl}/conversion-api`;
      const token = await getToken();
      const { data } = axios({
        url,
        method: 'POST',
        params: { site, platform: 'web' },
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        data: {
          eventName,
          payload,
          eventID
        },
      })
      .then((response) => {
        return data;
      }).catch(error =>{
        return null
      })
    }
  }
};

export const FBAllowEvents = (eventName) => {
  const allowEvents = (window.settings && window.settings.analyticalEvents && window.settings.analyticalEvents.facebook) || null

  if(allowEvents && eventName && allowEvents[eventName]){
    return allowEvents[eventName]
  } else{
    return false
  }
}

export const checkFBAllowEvents= (eventName) => {

  /*** Currently Allow for all the events **/
  return true

  /*** Start, Currently Disabled this checked. After testing we will upload on prod. **/
  // let allowEvent = null

  // if(((eventName == 'PageView') || 
  // (eventName == 'Purchase') || 
  // (eventName == 'AddPaymentInfo') || 
  // (eventName == 'InitiateCheckout') || 
  // (eventName == 'CompleteRegistration') || 
  // (eventName == 'AddToCart') || 
  // (eventName == 'StartTrial') || 
  // (eventName == 'Subscribe') || 
  // (eventName == 'ViewContent') || 
  // (eventName == 'AddToWishlist') || 
  // (eventName == 'RemovedFromWatchlist') || 
  // (eventName == 'SubscriptionInitiated') || 
  // (eventName == 'SubscriptionCompleted') || 
  // (eventName == 'SubscriptionFailed') || 
  // (eventName == 'Search') || 
  // (eventName == 'Login') || 
  // (eventName == 'Logout') || 
  // (eventName == 'Watched')) && 
  // FBAllowEvents(eventName)){
  //   allowEvent = true
  // } else if(((eventName == 'tvod_purchase') && FBAllowEvents('TvodPurchase')) || 
  // ((eventName == 'tvod_purchase_completion') && FBAllowEvents('TvodPurchaseCompletion')) || 
  // ((eventName == 'tvod_purchase_completion') && FBAllowEvents('TvodPurchaseCompletion')) || 
  // (eventName == 'Play Started') && FBAllowEvents('PlayStarted') || 
  // (eventName == 'Add Payment Info') && FBAllowEvents('AddPaymentInfo') || 
  // (eventName == 'Initiate Checkout') && FBAllowEvents('InitiateCheckout') || 
  // (eventName == 'Complete Registration') && FBAllowEvents('CompleteRegistration')){
  //   allowEvent = true
  // } else {
  //   allowEvent = false
  // }

  // return allowEvent
  /*** End, Currently Disabled this checked. After testing we will upload on prod. **/

}