import React, {Suspense} from 'react'
import get from 'lodash/get'
import connect from './tools/connect'
import initializeSkin from './tools/skinAd/index'
import pageStore from './redux/page/store'
import PopupView from './views/Popup'
import Share from './views/Share'
import ErrorPage from './views/ErrorPage'
import VLCookieConsent from '~/components/VLCookieConsent'
import AppStoreSuggestion from '~/components/AppStoreSuggestion'
import { trackEvent, clevertapAllowEvents } from '~/tools/analytics/cleverTap'
import { nestTabModules, applyModuleData } from "~/Modules"
import HomeSkeletonLoader from '~/components/skeletonLoader/HomeSkeletonLoader'
import PageSpinner from '~/components/PageSpinner'
import lazyLoad from './tools/lazy-load-images'
import NavFocusSkipper from '~/components/NavFocusSkipper'
import { signupOrSignIn, redirectToPageIfNoPlans } from '~/tools/signupOrSignIn'
import loadFreshChatBot from './tools/analytics/freshChatBot'
import { trackEventFunnel } from "~/tools/analytics/googleAnalytics";
import Cookies from 'js-cookie'
import userStore from './redux/user/store'
import './App.css'
import { getLocalizedVal } from '~/tools/localization/helpers';
import ReactHelmet from '~/components/ReactHelmet'
import { persolizationSetUpProfile, newsPersolizationSetUpProfile, login, newsLogin,personalizedData, VerimatrixErrorWall } from '~/views/Popup/popup'
import { personalizationPageCheck, userSelectedGenres, fetchPersonalizationPageData, getCategories,fetchPersonalizationPageDataSteps,getUserPersonalizationCheck, autoSendRecommendationCategories } from '~/redux/page/helpers'
import { generateUUID,verifyMagicLink } from "./helper"
/*  
    Most pages use slick so we are importing it at the Base 
    so that these imports are not needed 
    everytime the <Slider /> component is used.
*/
import 'slick-carousel/slick/slick.css'
import '~/modules/Carousel/slick-theme.css'
import '~/modules/Carousel/style.scss'
import LazyLoadModules from './LazyLoadModules';
import {updatepageStyle} from '~/tools/getPageLevelStyling'
import { subscribeNotificationWs } from './redux/user/helpers'
import { activateDevice } from './redux/devices/helpers'
import { adsRemarketingEvent, webConfigurationEvent } from '~/tools/analytics/gtmTags'
import { deferredInitCookieBot, deferredCookieDeclaration } from '~/tools/analytics/cookieBot'
import loadSatisfiChatJS from '~/tools/analytics/satisfiChat'
import insertOneTrustCookie from '~/tools/analytics/oneTrust'
import insertKetchCookie from '~/tools/analytics/ketch'

import initializeGtm from './tools/analytics/gtm'
import { initGa , initEnhancedECommerce } from './tools/analytics/googleAnalytics'
import { initializeWebWorker, initializeServiceWorker, removePushNotification, getRemovePushNotificationFlag } from './tools/webWorker'
import initFbEvent from './tools/analytics/fbEvent';
import { initGtmAdwords } from './tools/analytics/gtmTags';
import { displayPhoneNumberScreen } from '~/tools/checkPhoneNumber';
import IosSharePopup from '~/components/Ios/IosSharePopup'
import getQueryVariable from './tools/getQueryVariable'
import { updateQueryStringParameter } from './tools/updateQueryStringParameter'
import { fetchProfile } from "./tools/profile-actions";
import { displayContentFilterScreen } from '~/tools/contentFilterFunctions';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import verifyToken from '~/tools/analytics/recaptchaVerify';
import {trackFbEvent, FB_EVENTS, trackFbPixel, pixelEvents } from '~/tools/analytics/fbPixel';
import { checkGAVersion, initGA4, pageViewEvent, trackECEvent, GA4AllowEvents } from "~/tools/analytics/gtmTagsGA4";
import { getRoute } from '~/tools/utils';
import { fetchPage } from '~/redux/page/actions'
class App extends React.Component {
  
  constructor() {
    super()
    this.myRef = React.createRef();
    this.loadMore = false
    this.pageLoaded = true
    this.personalisationAlreayCompleted=false;
    this.state = {
      pageReload: false,
      MiniStandaloneVideoPlayer: null,
      // checking:false,
      smartTVActivation: false,
      loading: false,
      message: null,
      layout: null,
      checkingPersonalization: false,
      redirectToLandingPage: null,
    }
    this.profileDetailsRedirectPopup = this.profileDetailsRedirectPopup.bind(this);
    this.isRecaptchaEnabled = get(window, 'settings.analytics.recaptchaKeys.enableRecaptcha')  && get(window, 'settings.analytics.recaptchaKeys.siteKey') && get(window, 'settings.analytics.recaptchaKeys.recatpchaV2SiteKey') 
    this.isKetchConsentEnabled = get(window, 'settings.privacy.ketchCookiesConsent.enableKetchCookiesConsent')
    // window.activateCode = false 
  }
  resetRedirectPopup() {
    if (getQueryVariable("popup") === "notice02"){
      window.location.href = window.location.pathname;
    }
  }
  showRestrictPopup(){
    if (getQueryVariable("popup") !== "notice02" && !window.location.pathname.includes("user")){
      window.location.href = window.location.pathname + "?popup=notice02";
    }
  }
  profileDetailsRedirectPopup() {
    const userInfo = userStore.getState().user || {};
    if (userInfo.userId){
      //loggedIn
      const userProfile = Cookies.get("user-profile") ? JSON.parse(Cookies.get("user-profile")) : {};
      const isUserProfileValid = Object.keys(userProfile).length > 0;
      if (isUserProfileValid){
        const userProfileMetadataValues = Object.values(userProfile.metadata);
        const isProfileMetadataValid = userProfileMetadataValues.filter(k => k && k.length > 0 ? true : false).length > 0 ? true : false;
        if (isProfileMetadataValid){
          this.resetRedirectPopup();
        } else {
          this.showRestrictPopup();
        }
      } else {
        fetchProfile((res) => {
          if (res && res.metadata){
            const profileKeys = Object.values(res.metadata);
            const validProfileKeys = profileKeys.filter(k => k && k.length > 0 ? true : false).length > 0 ? true : false;
            if (validProfileKeys) {
              this.resetRedirectPopup();
            } else {
              this.showRestrictPopup();
            }
          } else {
            this.showRestrictPopup();
          }
        }, () => {
          this.showRestrictPopup();
        });
      }
    } else {
      //loggedOut
      this.resetRedirectPopup();
    }
  }

  generateId () {
    const s4 = _ => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }
  insertScripts = () => {
    let enableOmSDK = window.settings && window.settings.csaiTracking && window.settings.csaiTracking.enableOmSdk
    let enablePalSDK = window.settings && window.settings.csaiTracking && window.settings.csaiTracking.enablePalSDK
    let body = document.getElementsByTagName('body')[0];
    if (enableOmSDK) {
    let omidScriptTag = document.createElement('script');
    let omidSessionClientScript = document.createElement('script');
    omidScriptTag.type = 'text/javascript';
    omidSessionClientScript.type = 'text/javascript'; 
    omidScriptTag.src = 'https://appcmsprod.viewlift.com/assets/omweb-v1.js';
    omidSessionClientScript.src = "https://appcmsprod.viewlift.com/assets/omid-session-client-v1.js"
    body.appendChild(omidScriptTag)
    body.appendChild(omidSessionClientScript)
    }
    if (enablePalSDK) {
    let palSdkScript = document.createElement('script');
    palSdkScript.type = 'text/javascript';
    palSdkScript.src = "//imasdk.googleapis.com/pal/sdkloader/pal.js"
    body.appendChild(palSdkScript)
    }

    if (window.settings && window.settings.auth && window.settings.auth.googleClientId) {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://accounts.google.com/gsi/client';
      script.defer = true;
      head.appendChild(script);
    }
    window.onGoogleLibraryLoad = () => {
      window.gsi = true
    };
  }
  

  

  getSocialAppIdFunction () {
    const getSocialAppId = window.settings.notifications && window.settings.notifications.getSocialAppId
    if (getSocialAppId) {
      import('./tools/analytics/getSocialEvent').then(getSocialCId => {
        let {setGetSocialCId} = getSocialCId
        setGetSocialCId();
      });
    }
  }
  importScripts () {
    this.getSocialAppIdFunction()
    const queryString = document.location.search.slice(1)
    if (queryString !== "") {
      import('./tools/analytics/utm').then(updateUtm => {
        let {utm} = updateUtm
        utm()
      });
    }
    const existingScript = document.getElementById('platform');
    if (!existingScript) {
      import('./tools/externalSDK').then(platformFunc => {
        let {platform} = platformFunc
        platform()
      });
    }
  }

  checkForceLoginAndRedirect(){
    const settings = window.settings
    const isForceLogin=(settings && settings.forceLogin) || false;
    const userInfo = userStore.getState().user || {};
    const enableMandatoryRedirect = (settings && settings.navigation && settings.navigation.settings.primaryCta.enableMandatoryRedirect) || null
    const mandatoryRedirectUrl = (enableMandatoryRedirect && settings && settings.navigation && settings.navigation.settings.primaryCta.mandatoryRedirectUrl) || null

    if (!(userInfo.userId) && isForceLogin){
      login({hideCloseButton:true});
    } else if (!(sessionStorage.getItem('skipPlanPage')) && (userInfo && userInfo.userId && !(userInfo.isSubscribed)) && mandatoryRedirectUrl && (window.location.pathname !== mandatoryRedirectUrl) && (window.location.pathname !== '/juspay-payment')){
      window.location.href = mandatoryRedirectUrl
    } else
      return;
  }

  redirectToLandingPage = (landingPage) => {  
    getCategories()
    .then((res) => {
      window.location.href = landingPage
      this.setState({loading: false, redirectToLandingPage: false})
      return true
    })
  }
  checkLandingPage = (userInfo) => {
    this.setState({loading: true, redirectToLandingPage: true})
    const getUserId = (userInfo && userInfo.userId) || null;
    const landingPage = '/welcome'
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let popupName = urlParams.get('popup');
    
    if (!getUserId && (window.settings.site === 'nhlgc-live') && (window.location.pathname === landingPage)) {
      // Don't redirect to any page;
      const teamsData = Cookies.get('teamsData') || [];
      if(teamsData === 'no_team'){
        this.setState({loading: false, redirectToLandingPage: false})
      } else if(teamsData && teamsData.length < 1){
        this.redirectToLandingPage(landingPage)
      } else {
        this.setState({loading: false, redirectToLandingPage: false})
      }
    } else if (!getUserId && (popupName !== 'login') && (window.settings.site === 'nhlgc-live') && (window.location.pathname !== landingPage)) {
      // redirect to landing page;
      this.redirectToLandingPage(landingPage)
    } else {
      (!getUserId && popupName === 'login' && (window.settings.site === 'nhlgc-live')) ? this.setState({checkingPersonalization: true}) : this.setState({checkingPersonalization: false})
      this.setState({loading: false, redirectToLandingPage: false})
      this.checkForceLoginAndRedirect()
      return false
    }
  }
  pageLoadOnLanguageSelection = () => {
    const param = new URLSearchParams(window.location.search)
    const getLangCode = (param && param.get('lang')) || null

    if(getLangCode && (Cookies.get('userLanguageSelection') !== getLangCode)){
      this.setState({redirectToLandingPage: true})
      const route = getRoute()
      Cookies.set('userLanguageSelection', getLangCode)
      Cookies.set('userLanguageTrigger', true)
      pageStore.dispatch(fetchPage(route, getLangCode))
    }
  }

  componentDidMount() {
    const redirectReferrer = document.referrer
    const currentPath =  window.location.href
    if(redirectReferrer.includes('https://api.cld.dtvce.com') && !currentPath.includes('aisresponse')){
      VerimatrixErrorWall()
    }
    var ref = this;
    const userInfo = userStore.getState().user || {};
    this.pageLoadOnLanguageSelection()
    this.checkLandingPage(userInfo)
    redirectToPageIfNoPlans()
    const removePushNotificationFlag = getRemovePushNotificationFlag();
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let activationCode = urlParams.get('code');
    const authToken = urlParams.get("auth");
    if(activationCode && activationCode !== null){
      window.sessionStorage.setItem('deviceCode', activationCode)
    }
    let currentDevice = localStorage.getItem('deviceId');
    let isActivateCode = Cookies.get('isActivateCode');
    let isDeviceActivateMessage = Cookies.get('isDeviceActivateMessage');
    if(isActivateCode && !isDeviceActivateMessage){
      activateDevice(isActivateCode, userInfo.userId)
      .then(res => {
        if(res.data && res.data.status === 'success'){
          this.setState({
            smartTVActivation: true,
            message: getLocalizedVal(window.settings, 'genericMessages', 'activateDeviceSuccessMessage') || 'Device has been Activated!'
          })
          if(sessionStorage.getItem('deviceCode')){ 
            sessionStorage.removeItem("deviceCode")
          }
          setTimeout(() => {
            this.setState({smartTVActivation: false })
            Cookies.remove('isActivateCode')
            window.location.href = window.location.pathname 
          },4000)
        }
      }) 
      .catch(res => {
        let errorCode = res && res.response && res.response.data && res.response.data.errorMessage;
        this.setState({
          smartTVActivation: true,
          message: errorCode || 'Code is invalid!'
        })
        if(sessionStorage.getItem('deviceCode')){ 
          sessionStorage.removeItem("deviceCode")
        }
        setTimeout(() => {
          this.setState({smartTVActivation: false }) 
          Cookies.remove('isActivateCode')
        },4000)
      })
    }else if(isDeviceActivateMessage){
      this.setState({
        smartTVActivation: true,
        message: isDeviceActivateMessage
      })
      if(sessionStorage.getItem('deviceCode')){ 
        sessionStorage.removeItem("deviceCode")
      }
      setTimeout(() => {
        this.setState({smartTVActivation: false }) 
        Cookies.remove('isDeviceActivateMessage')
      },4000)
    }

    if(authToken){
      verifyMagicLink(authToken);
    }
    if(userInfo && userInfo.userId && activationCode && activationCode !== null) {
      this.setState({
        loading: true
      });
      activateDevice(activationCode, userInfo.userId)
      .then(res => {
        if(res.data && res.data.status === 'success'){
          this.setState({
            smartTVActivation: true,
            loading: false,
            message: getLocalizedVal(window.settings, 'genericMessages', 'activateDeviceSuccessMessage') || 'Device has been Activated!'
          })
          if(userInfo && userInfo.isSubscribed == true) {
            setTimeout(() => {
              this.setState({smartTVActivation: false })
              window.location.href = "/" 
            },5000)
          }else {
            setTimeout(() => {
              this.setState({smartTVActivation: false })
              window.location.href = window.location.pathname 
            },4000)
          }
        }
      }) 
      .catch(res => {
        let errorCode = res && res.response && res.response.data && res.response.data.errorMessage;
        this.setState({
          smartTVActivation: true,
          loading: false,
          message: errorCode || 'Code is invalid!'
        })
        if(userInfo && userInfo.isSubscribed == true) {
          setTimeout(() => {
            this.setState({smartTVActivation: false })
            window.location.href = "/" 
          },5000)
        }else {
          setTimeout(() => {
            this.setState({smartTVActivation: false }) 
          },4000)
        }
      }) 
    }else if(currentDevice && currentDevice !== "null") {
      localStorage.setItem('deviceId', currentDevice);
    }else {
      let deviceId = 'browser-'+ref.generateId()
      localStorage.setItem('deviceId', deviceId);
    }  
    ref.importScripts()
    initializeSkin(get(this.state, 'page.data.id'))
    setTimeout(lazyLoad, 2000)
    get(window, "settings.features.enablePWA") &&
      window.addEventListener("beforeinstallprompt", event => {
        window.deferredPrompt = event;
      });
    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad)
    const profileRequired = get(window, "settings.features.profileRequired", false);
    if (profileRequired) {
      ref.profileDetailsRedirectPopup();
    } else {
      ref.resetRedirectPopup();
    }
    if (!window.SERVER_SIDE_RENDERING && window.sessionStorage && !window.sessionStorage.getItem('isApp')) {
      deferredInitCookieBot();
    }
    if (userInfo && userInfo.isSubscribed === false && userInfo.subscriptionStatus !== "COMPLETED") 
      Cookies.get('isPaymentinProcess') && subscribeNotificationWs();
    else Cookies.remove('isPaymentinProcess');
    
    removePushNotificationFlag && removePushNotification();
    initializeServiceWorker()
    
    if(checkGAVersion('GA')){
      initGa()
      if (window.settings.serviceType === 'SVOD') {
        initEnhancedECommerce()
      }
      initGtmAdwords()
    }
    initializeWebWorker()
    const oneTrustEnabled = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.enable) || null;
    if(oneTrustEnabled){
      initGA4()
      initializeGtm()
      initFbEvent()
    }
  
    displayPhoneNumberScreen()
    displayContentFilterScreen()
    if (getQueryVariable("token") && getQueryVariable("ba_token") && getQueryVariable("popup") !== "createSubscription"){
      window.location.href = updateQueryStringParameter(window.location.href, "popup", "createSubscription");
    }
    var userPersonalization = getQueryVariable('popup')
    let forcedPersonalizationCheck = (userInfo && userInfo.userId && personalizationPageCheck(userInfo.userId)) || null
    if((userPersonalization === "userPersonalization" || forcedPersonalizationCheck) && ((window.settings.recommendation && window.settings.recommendation.isPersonalization))) {
      if(userInfo && userInfo.userId){
        const signup = signupOrSignIn() || ''
        if(forcedPersonalizationCheck) {
          // this.setState({checkingPersonalization: true})
          // fetchPersonalizationPageData()
          // .then((res) => {
          //   if(res) {
          //     userSelectedGenres(userInfo.userId, persolizationSetUpProfile,res.blockName)
          //     .then((genre) => {
          //       this.setState({checkingPersonalization: genre})
          //     })  
          //   }
          // })

          if(signup === 'Signup'){
            autoSendRecommendationCategories()
          }
        } else if(window.settings.templateName === "News") {
          newsPersolizationSetUpProfile(true)
        } else {
          if(!(sessionStorage.getItem('personalizationShown'))){
            persolizationSetUpProfile()
          }
        }
      } else {
        window.settings.templateName === "News" ? newsLogin() : login()
      }
    } else if(userPersonalization === "userPersonalization" && !(window.settings.recommendation && window.settings.recommendation.isPersonalization)) {
      if(window.location.href.includes("?")) {
        window.history.pushState(null, '', window.location.href.split("?")[0]);
      }
    }
    else if((window.settings.recommendation && window.settings.recommendation.isPersonalization) && (window.settings.recommendation && window.settings.recommendation.showPersonlisationSteps && !(Cookies.get("hide_personalization")) && userInfo && userInfo.userId && window.location.pathname !== `/activate`)){
      getUserPersonalizationCheck().then((res)=>{
        this.personalisationAlreayCompleted=res;
        if(userInfo && userInfo.userId && !(Cookies.get("hide_personalization")) && !this.personalisationAlreayCompleted){
          fetchPersonalizationPageDataSteps() 
          .then(res=>{
            if(res){
              personalizedData()
            }
          })
        }
      })
      }
    ref.insertScripts()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page) {
      var { title } = this.state.page || {}

      if(GA4AllowEvents('PageView')){
        if(checkGAVersion('GA4')){
          pageViewEvent('page_view', {
            'source': 'Web', 
            'pageName': title,
            'loginSignup' : signupOrSignIn()
            }
          );
        }
      }
      if(checkGAVersion('GA')){
        adsRemarketingEvent('page_view', {
          'source': 'Web', 
          'pageName': title,
          'loginSignup' : signupOrSignIn()
          }
        );
        webConfigurationEvent('page_view', {
          'source': 'Web', 
          'pageName': title
        })
      }
      
      const isUserSettingsPage = get(this.state, "page.route", "").includes("/user")
      loadFreshChatBot(isUserSettingsPage ? 1 : NaN);

      if (!window.SERVER_SIDE_RENDERING && window.sessionStorage && !window.sessionStorage.getItem('isApp')) {
        deferredCookieDeclaration();
        (window.settings.notifications && window.settings.notifications.satisfiChatId) && loadSatisfiChatJS();
        (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.enable) && insertOneTrustCookie();
        this.isKetchConsentEnabled && insertKetchCookie()
      }

      if(!this.state.MiniStandaloneVideoPlayer){
        this.addMiniStandalonePlayer();
      }
    }
  }

  triggerPageViewd = async () => {
    var { title } = this.state.page || {}
    if (title && this.pageLoaded) {
      this.pageLoaded = false
      var lastActivity = null
      if (document.referrer && document.referrer !== window.location.href) {
        lastActivity = document.referrer
      }
      if((title.toLowerCase()).includes("plans")){
        Cookies.set('viewPlans', true);
        if(GA4AllowEvents('ViewPlans')){
          if(checkGAVersion('GA4')){
            trackECEvent("trackFunnel", "viewPlans")
          } 
        }
        if(checkGAVersion('GA')){
          trackEventFunnel("trackFunnel", "viewPlans")
        }
      }
      let dedup_event_id = generateUUID()
      trackFbEvent(FB_EVENTS.PAGE_VIEW, {}, dedup_event_id);
      if(clevertapAllowEvents('PageViewed')){
        trackEvent('Page Viewed', {
          pageName: title,
          pageURL: window.location.href,
          lastActivityName: lastActivity,
          source: lastActivity,
          platform: 'Web'
        })
      }
      
      const { executeRecaptcha } = this.props.googleReCaptchaProps;
      if (executeRecaptcha && this.isRecaptchaEnabled) {
        const token = await executeRecaptcha(title.split(' ').join('_'));
        const result = await verifyToken(token);
        console.log('Score ==>', result);
      }
      trackFbPixel(pixelEvents.PAGE_VIEW, {}, dedup_event_id);
    }
  }

  addMiniStandalonePlayer = () => {
    let add_site_level_mini_player = window.settings.features.add_site_level_mini_player;
    let { layouts } = this.state.page || {};

    if(!add_site_level_mini_player || !layouts) return;
    if(layouts.length){
      for(let layout of layouts){
        // whitelist current mini player for smooth deployment
        if(layout.type === 'AC StandaloneVideoPlayer 03') return true;
        // not display mini player on page with a standalone video player or VideoPlayerWithInfo
        else if(layout.type.includes('StandaloneVideoPlayer') || layout.type.includes('VideoPlayerWithInfo')) return;
      }
    }
    if(add_site_level_mini_player){
      import('~/modules/NewsStandaloneVideoPlayer/miniplayer')
      .then(MiniStandaloneVideoPlayer=>{
        this.setState({
            MiniStandaloneVideoPlayer: MiniStandaloneVideoPlayer.default
        });
      })
    }
  }
  render() {
    if(this.errored && window.settings.features.custom404PageEnabled && window.location.pathname !== '/404'){
      window.location.href = "/404" 
    }
    var { modules, layouts } = this.state.page || {}
    var classes = ['app', 'appcms', window.SERVER_SIDE_RENDERING ? 'server-side-rendering' : '']
    var currentPage = window.location.pathname;
    const isPWAEnabled = get(window, "settings.features.enablePWA", false);
    const userInfo = userStore.getState().user || {};
    let deviceLimitError = Cookies.get('MAX_DEVICE_ERROR') 
    let removeDeviceError = Cookies.get('remove_error')
    // override styling with page specific styling if flag enabled
    if(get(this.state, 'page.styles.enableStyle') === true) {
      updatepageStyle(this.state.page && this.state.page.styles);
    }
    let userDevicesExceedError = getLocalizedVal(window.settings,'genericMessages', 'userDevicesExceedError') || 'User has exceeded the device limit. Go to device management and remove the devices.';
    let fontBackgroundColor = get(window, "settings.brand.cta.primary.backgroundColor");
    let fontColor = get(window, "settings.brand.cta.primary.textColor");
    let fontStyle = {
      backgroundColor : fontBackgroundColor,
      color: fontColor
    }
    if (this.state.fetchingPage || this.state.adobeStatus) {
      if (currentPage.includes("/user")) {
        return !window.SERVER_SIDE_RENDERING && <PageSpinner color={fontBackgroundColor} />
      } else {
        return !window.SERVER_SIDE_RENDERING && <HomeSkeletonLoader />
      }
    }
    
    if (this.state.loading)
      return (<div className="loading centered"><PageSpinner color={fontBackgroundColor} /></div>)
    
    const { MiniStandaloneVideoPlayer,message,loading,smartTVActivation } = this.state;
    const isEmbedVideo = window.location.href.indexOf('embed/player') > -1
    let TabNavBar
    if(isPWAEnabled && !isEmbedVideo) {
      TabNavBar = React.lazy(() => import('~/components/TabNavBar'));
    }
    const oneTrustEnabled = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.enable) || null;
    const hideVLConsent = (window.settings.privacy && window.settings.privacy.vlCookiesConsent && window.settings.privacy.vlCookiesConsent.hideVLCookiesConsent) || null;
    const currentPath = window && window.location.pathname
    return (
      <div ref={el => this.wrapper = el}>
        <div ref={this.myRef} className={classes.join(' ')}>
          <NavFocusSkipper />
          <ReactHelmet triggerPageViewd={this.triggerPageViewd}/>
          {window.settings.skinAdTag && <div id="viewlift-site-skin"></div>}
          
          <div role="main" className={`modules ` + (window.settings.skinAdTag && 'skin-ad')}>
            {((userInfo && userInfo.code === 'DEVICE_LIMIT_EXCEEDED') || deviceLimitError) && !removeDeviceError && <div style={fontStyle} className="deviceError">{userDevicesExceedError}</div>}
            {smartTVActivation == true && <div style={fontStyle} className="deviceError">{message}</div>} 
            {this.loaded && window.settings.features.app_download_suggestion && !isEmbedVideo && 
              <AppStoreSuggestion />
            }
            {MiniStandaloneVideoPlayer && <MiniStandaloneVideoPlayer/>}
            {
             !(this.state.redirectToLandingPage) && !this.state.checkingPersonalization && this.loaded && this.renderModules(layouts, modules)
            }
            <LazyLoadModules pageReload={this.loadMore} layouts={layouts} page={this.state.page}/>
            {this.errored && <ErrorPage error={this.state.page.error} />}
          </div>
          {isPWAEnabled && !isEmbedVideo && <Suspense fallback={<div>Loading...</div>}><TabNavBar/></Suspense>}
          {!isEmbedVideo && <IosSharePopup />}
          
          {!hideVLConsent && !oneTrustEnabled && !this.isKetchConsentEnabled && currentPath !== "/embed/player" && this.loaded &&
            <VLCookieConsent />
          }
          {this.loaded && this.state.page.modules && this.state.page.modules.length > 0 && <PopupView />}
          {this.loaded && <Share />}
        </div>
      </div>
    )
  }

  get errored() {
    return this.state.page.error != null;
  }

  get loaded() {
    return this.state.page && !this.state.page.error && this.state.page.modules && this.state.page.modules.length > 0
  }

  renderModules(layouts, modules,metadataMap) {
    /*
        This nestTabModules conditional was added because the Template Builder does not currently
        have a way to support nesting. This is basically a hacky workaround that splits up the modules
        and nests them for pages that have a Tabs Module.
    */
    let tabModule = layouts && layouts.filter(moduleLayout => {
      if (!moduleLayout.view) {
        return false;
      }
      return moduleLayout.view.includes('Tabs');
    })

    if (tabModule && tabModule.length > 0) {
      return nestTabModules(layouts, modules, tabModule);
    }
    else {
      return applyModuleData(layouts, modules,metadataMap)
    }
  }
}

connect(App, state => ({ page: state.data, fetchingPage: state.fetchingPage, adobeStatus: state.adobeStatus }), pageStore)

export default withGoogleReCaptcha(App);
