export const popupConstants = {
    billingHistory: './views/BillingHistory',
    cancelSubscription: './views/CancelSubscription',
    changePassword: './views/ChangePassword',
    contentPlans: './views/ContentPlans',
    createSubscription: './views/CreateSubscription',
    createSubscription02: './views/CreateSubscription02',
    customOverlay: './views/CustomOverlay',
    EpisodeOverlay: './views/EpisodeOverlay',
    equipment: './views/Equipment',
    error: './views/error',
    fitnessCheckout: './views/FitnessOnboarding/Checkout',
    fitnessChooseAPlan: './views/FitnessOnboarding/ChooseAPlan',
    fitnessOnboardingScreen: './views/FitnessOnboarding/OnboardingScreen',
    fitnessSetupProfile: './views/FitnessOnboarding/SetupProfile',
    fitnessSignInCreate: './views/FitnessOnboarding/SignInCreate',
    forgotPassword: './views/ForgotPassword',
    ideal: './views/ideal',
    login: './views/Login',
    loginEmailPassword: './views/LoginEmailPassword',
    manageDevices: './views/ManageDevices',
    newsEpisodeOverlay: './views/NewsEpisodeOverlay',
    newsForgotPassword: './views/NewsForgotPassword',
    newsLogin: './views/NewsLogin',
    notice: './views/Notice',
    notice02: './views/Notice02',
    persolizationSetUpProfile: './views/Personalization',
    sportsPersolizationSetUpProfile: './views/SportsPersonalization',
    pinOverlay: './views/PinOverlay',
    providerList: './views/ProviderList',
    providerListVerimatrix: './views/ProviderList/verimatrix',
    purchase: './views/Purchase',
    purchase02: './views/Purchase02',
    search: './views/Search',
    searchList: './views/SearchList',
    setupPin: './views/setupPin',
    thankYou: './views/ThankYou',
    updateNumber: './views/UpdateNumber',
    updateProfile: './views/UpdateProfile',
    updatePreferences: './views/UpdatePreferences',
    updateStripePayment: './views/UpdateStripePayment',
    upgradeSubscription: './views/UpgradeSubscription',
    verifyOTPScreen: './views/VerifyOTP',
    verifyByDoubleOTP: './views/VerifyOTP2',
    viewingRestrictions: './views/ViewingRestrictions',
    workoutEpisodeOverlay: './views/WorkoutEpisodeOverlay',
    passwordAuth: './views/PasswordAuth',
    referredUsers: './views/ReferredUsers',
    newsPersolizationSetUpProfile: './views/newsPersonalization',
    confirmGooglePayNumber: './views/ConfirmGooglePayNumber',
    lockedContentPopup: './views/LockedContentPopup',
    appliedOffers: './views/AppliedOffers',
    subscribe: './views/Resubscribe',
    contentFilter: './views/ContentFilter',
    personalizedData:'./views/UsersPersonalization',
    vppaData:'./views/VppaData',
    shareLocation:'./views/ShareLocation',
    homeBasedAuthentication:'./views/HomeBasedAuthentication',
    vppaConsentInvoke:'./views/VppaConsentInvoke',
    VerimatrixErrorWall: './views/VerimatrixErrorWall'
}