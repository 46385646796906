import get from 'lodash/get';
import axios from 'axios';
import { getToken } from '~/redux/user/tokens';
import {trackEvent as GAtrackEvent} from '~/tools/analytics/googleAnalytics';
import { checkGAVersion, trackEvent as GA4trackEvent, GA4AllowEvents } from '~/tools/analytics/gtmTagsGA4';

const verifyToken = async (response) => {
  const site = get(window, 'settings.internalName');

  const token = await getToken();

  const { data } =  await axios({
    method: 'POST',
    url: `${window.settings.trackingBaseUrl}/google-captcha`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      'x-api-key': window.settings.baseAPIKey
    },
    params: { site },
    data: {
      response
    },
  });

  if(GA4AllowEvents('RecaptchaAction')){
    if(checkGAVersion('GA4')){
      GA4trackEvent('RecaptchaCategory', 'Recaptcha Action', { source: data.action || null, platform: 'Web', score: data.score })
    }
  }
  if(checkGAVersion('GA')){
    GAtrackEvent('Recaptcha Action', { source: data.action || null, platform: 'Web', score: data.score })
  }

  const validCaptchaScore = get(window, 'settings.analytics.recaptchaKeys.thresholdValue') || 0.4
  const isCaptchaVerified = +data.score >= +validCaptchaScore

  return isCaptchaVerified
};

export default verifyToken;
