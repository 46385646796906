import store from '~/redux/user/store';
import {addClevertapWhatsAppConsent} from "~/redux/user/actions";

var cleverTapAnalyticsId = (window.settings || {}).cleverTapAnalyticsId
var notificationsConfig = window.settings.notifications || {}
export function initCleverTap() {
  if(cleverTapAnalyticsId){
    if(notificationsConfig && notificationsConfig.region){
      window.clevertap = {event:[], region: notificationsConfig.region, profile:[], account:[], onUserLogin:[], notifications:[], privacy:[]};
    } else {
      window.clevertap = {event:[], profile:[], account:[], onUserLogin:[], notifications:[], privacy:[]};
    }
    
    // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
    window.clevertap.account.push({"id": cleverTapAnalyticsId});
    window.clevertap.privacy.push({optOut: false}); //set the flag to true, if the user of the device opts out of sharing their data
    window.clevertap.privacy.push({useIP: true}); //set the flag to true, if the user agrees to share their IP data
    (function () {
            var wzrk = document.createElement('script');
            wzrk.type = 'text/javascript';
            wzrk.async = true;
            wzrk.src = ('https:' == document.location.protocol ? 'https://d2r1yp2w7bby2u.cloudfront.net' : 'http://static.clevertap.com') + '/js/clevertap.min.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wzrk, s);
    })();
  }
}

export function trackEvent(eventName, eventData) {
  var clevertap = window.clevertap
  if(clevertap){ clevertap.event.push(eventName, eventData) }
}

export function addUserProfile(profileName, profileData,updateProfile) {
  var clevertap = window.clevertap
  // if(updateProfile & clevertap){
  //   setTimeout(function(){       // Added delay in user profile update to avoid conflicts with user's onUserLogin method
  //     clevertap.profile.push({ [profileName]: profileData })
  //    }, 3000);
  // }
  if(clevertap){ 
    clevertap.onUserLogin.push({ [profileName]: profileData }) 
  }
}
export function updateUserProfile(profileName, profileData) {
  var clevertap = window.clevertap
  if(clevertap){ 
    clevertap.profile.push({ [profileName]: profileData }) 
  }
}

export function logoutUser() {
  var clevertap = window.clevertap
  if(clevertap){ trackEvent('Logout', {'platform' : 'Web'}); }
}

export function getPlaySourcePrefix(){
  return window.sessionStorage.getItem('playSourcePrefix') || ''
}

export function setPlaySourcePrefix(prefix){
  window.sessionStorage.setItem('playSourcePrefix', prefix)
}

export function setCurrentPage(page){
  var prevPage = window.sessionStorage.getItem('currentPage')
  window.sessionStorage.setItem('currentPage', page)
  if(prevPage !== page){
    window.sessionStorage.setItem('prevPage', prevPage)
  }
}

export const clevertapWhatsAppConsent = (whatsappConsent, phoneNumber) => {
  const clevertapWhatsappData = {
    whatsappConsent: whatsappConsent, 
    phoneNumber: phoneNumber
  }
  window.settings.features && window.settings.features.whatsappConsent && window.settings.features.whatsappConsent.enableWhatsappConsent && store.dispatch(addClevertapWhatsAppConsent(clevertapWhatsappData))
}

export const clevertapAllowEvents = (eventName) => {
  const allowEvents = (window.settings && window.settings.analyticalEvents && window.settings.analyticalEvents.clevertap) || null

  if(allowEvents && eventName && allowEvents[eventName]){
    return allowEvents[eventName]
  } else{
    return false
  }
}
